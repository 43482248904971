import * as React from "react";
import styled from "@emotion/styled";
import PlaceholderGraphic from "../icons/Placeholder-graphic.svg";
import { css, useTheme } from "@emotion/react";
import Image from "./image";
import { GatsbyImage, withArtDirection, getImage } from "gatsby-plugin-image";
import PageLink from "./PageLink";

const InsightItemBoxSmall = styled.div`
  /* max-width: 354px; */
  margin: 0 1.5rem 5.5rem;
  .gatsby-image-wrapper {
    height: 200px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: unset;
    margin-bottom: 6rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    .gatsby-image-wrapper {
      height: 228px;
    }
  }
`;

const InsightItemDate = styled.p`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 1.3rem;
  line-height: 23px;
  letter-spacing: 2.34px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkRed};
  margin-top: 1.75rem;
`;

const InsightItemTitle = styled.h4`
  font-family: ${props => props.theme.fonts.medium};
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 32px;
  letter-spacing: 0.75px;
  color: ${props => props.theme.colors.offBlack};
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    min-height: 65px;
  }
`;

const InsightItemLink = styled(PageLink)`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 1.8rem;
  line-height: 25px;
  letter-spacing: 2.7px;
  color: ${props => props.theme.colors.lighterPurple};
  text-transform: uppercase;
  text-decoration: underline;
`;
const InsightCardSmall = ({
  insight: {
    Insight,
    categories,
    databaseId,
    date,
    status,
    tags,
    terms,
    title,
    uri,
  },
  ...props
}) => {
  const theme = useTheme();
  const sources = withArtDirection(
    getImage(Insight.cardThumbnailMobile?.localFile),
    [
      {
        image: getImage(Insight.cardThumbnailDesktop?.localFile),
        media: `(min-width: ${theme.breakpoints.sm})`,
      },
    ]
  );
  return (
    <InsightItemBoxSmall className={props.entry}>
      <PageLink to={uri}>
        {Insight.cardThumbnailDesktop || Insight.cardThumbnailMobile ? (
          <GatsbyImage
            image={sources}
            backgroundColor={`transparent`}
            alt={`${title} thumbnail image`}
          />
        ) : (
          <Image
            css={css`
              width: 100%;
              height: auto;
            `}
            image={<PlaceholderGraphic />}
          />
        )}
      </PageLink>
      <InsightItemDate>
        {categories.nodes?.map(category => category?.name)?.join(", ")} &mdash;{" "}
        {date}
      </InsightItemDate>
      <InsightItemTitle>
        <PageLink to={uri}>{title}</PageLink>
      </InsightItemTitle>
      <InsightItemLink to={uri}>
        {Insight.cardCtaText || `read more`}
      </InsightItemLink>
    </InsightItemBoxSmall>
  );
};
export default React.memo(InsightCardSmall);
